<template>
  <div>
    <div class="cui__utils__heading">
      <strong>Функционал: Рассылки</strong>
    </div>

    <div class="card">
      <div class="card-header card-header-flex">
        <div class="d-flex flex-column justify-content-center mr-auto">
          <h5 class="mb-0">Просмотр рассылки</h5>
        </div>
      </div>

      <div class="card-body">
        <div class="card-body">
          <a-form-model>
            <a-form-model-item label="Тип" required>
              <a-select v-if="notice.status" placeholder="выберите статус" :default-value="[notice.status]" disabled>
                <a-select-option v-for="(status, index) in statuses" :key="index">
                  {{ status }}
                </a-select-option>
              </a-select>
            </a-form-model-item>

            <a-form-item label="Отправить всем">
              <div class="d-flex align-items-center">
                <a-switch v-model="notice.all" disabled />
              </div>
            </a-form-item>

            <a-form-model-item label="Получатели" v-if="!notice.all">
              <a-select
                v-if="users.length"
                :default-value="[...users.map(u => u.id)]"
                :default-active-first-option="false"
                disabled
                multiple="true"
                mode="multiple"
                style="width: 100%"
              >
                <a-select-option v-for="(user, index) in users" :key="index" :value="user.id">
                  {{ user.first_name }} {{ user.last_name }} {{ user.email }}
                </a-select-option>
              </a-select>
            </a-form-model-item>

            <a-form-model-item ref="active" label="Тема(ru)">
              <a-input :value="notice.ru.title" disabled />
            </a-form-model-item>
            <a-form-model-item ref="active" label="Тема(ua)">
              <a-input :value="notice.ua.title" disabled />
            </a-form-model-item>
            <a-form-model-item ref="active" label="Содержимое(ru)">
              <a-input :value="notice.ru.name" disabled />
            </a-form-model-item>
            <a-form-model-item ref="active" label="Содержимое(ua)">
              <a-input :value="notice.ua.name" disabled />
            </a-form-model-item>
             <a-form-model-item ref="active" label="Лого">
              <a-input :value="notice.ru.mail.logo" disabled />
            </a-form-model-item>
             <a-form-model-item ref="active" label="Заголовок 1">
              <a-input :value="notice.ru.mail.links[0].title" disabled />
            </a-form-model-item>
             <a-form-model-item ref="active" label="Ссылка 1">
              <a-input :value="notice.ru.mail.links[0].url" disabled />
            </a-form-model-item>
           <a-form-model-item ref="active" label="Заголовок 2">
              <a-input :value="notice.ru.mail.links[1].title" disabled />
            </a-form-model-item>
             <a-form-model-item ref="active" label="Ссылка 2">
              <a-input :value="notice.ru.mail.links[1].url" disabled />
            </a-form-model-item>
            <a-form-model-item ref="active" label="Заголовок 3">
              <a-input :value="notice.ru.mail.links[2].title" disabled />
            </a-form-model-item>
             <a-form-model-item ref="active" label="Ссылка 3">
              <a-input :value="notice.ru.mail.links[2].url" disabled />
            </a-form-model-item>
            <a-form-model-item ref="active" label="Заголовок 4">
              <a-input :value="notice.ru.mail.links[3].title" disabled />
            </a-form-model-item>
             <a-form-model-item ref="active" label="Ссылка 4">
              <a-input :value="notice.ru.mail.links[3].title" disabled />
            </a-form-model-item>
          </a-form-model>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'id',

  data() {
    return {
      users: [],
      notice: {},
      statuses: [],
    }
  },

  async created() {
    const { data: { data: { notice } } } = await this.$services.get(`admin/notice/edit/${this.$route.params.id}`)
    this.notice = notice
    this.users = this.notice.recipient
    this.statuses.push(this.notice.status)
    if (this.notice.all === 0) {
      this.notice.all = false
    }
    if (this.notice.ru.mail.logo === null) {
      this.notice.ru.mail.logo = ''
    }
    for (let i = 0; i < this.notice.ru.mail.links.length; i += 1) {
      if (this.notice.ru.mail.links[i].title === null) {
        this.notice.ru.mail.links[i].title = ''
      }
      if (this.notice.ru.mail.links[i].url === null) {
        this.notice.ru.mail.links[i].url = ''
      }
    }
  },
}
</script>

<style scoped lang="scss">
.ant-form {
  ::v-deep .ant-form-item-label {
    height: fit-content;
    line-height: 120%;
    label {
      height: fit-content;
    }
  }
  .ant-input-disabled, .ant-select-disabled {
    background-color: #ffffff;
    color: #595c97;
    ::v-deep .ant-select-selection {
      background-color: #ffffff;
    }
  }
  ::v-deep .ant-form-item-control {
    line-height: 30px;
  }
}
</style>
